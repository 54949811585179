.App {
  text-align: center;
}

/* navbar */
.navbar{
  
  width:100%;
  background: #79af8e;  
  background: -webkit-linear-gradient(to top, #4f534f, #79af8e); 
  background: linear-gradient(to top, #4f534f, #79af8e); 
  font-weight: bold;
  font-size: large;
}

.navbar.active{
  background: #000000;  
background: -webkit-linear-gradient(to bottom, #434343, #000000);  
background: linear-gradient(to bottom, #434343, #000000); 
}

/* homepapge */
.homepage-intro{
  background-color: aliceblue;
}

.homepage-cards{
    margin-left: 30px;
    margin-top: 5px;
    box-shadow: rgba(27, 109, 27, 0.17) 0px -23px 25px 0px inset, rgba(32, 102, 26, 0.15) 0px -36px 30px 0px inset, rgba(29, 116, 44, 0.1) 0px -79px 40px 0px inset, rgba(89, 180, 94, 0.06) 0px 2px 1px, rgba(101, 187, 90, 0.09) 0px 4px 2px, rgba(29, 83, 38, 0.09) 0px 8px 4px, rgba(41, 119, 41, 0.09) 0px 16px 8px, rgba(43, 151, 43, 0.09) 0px 32px 16px;
   
}


/*Products-sidebar*/

.Sidebar{
  background-color: green;
}

.sidebar-row:hover{
  cursor: pointer;
  background-color: rgb(193, 224, 192);
}

.Sidebarlist #active{
  background-color: #1e4934;
}

/* products*/

.Product-img{
  transition: transform .2s;

}

.Product-img:hover{
  transform: scale(1.2);
}

/*contact*/

.contact-section {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
 
  
}
.contact-container {
  width: 90%;
  max-width: 1000px;
  margin: 50px auto;
  display: flex;
  align-items: center;
  justify-content: center;

   
}

.left {
  width: 50%;
  height: 600px;
  border-radius: 25px;
  background: url(./Assets/Image/Contact/contactcardimg.jpg) no-repeat ;
  
  
}
.right {
  width: 50%;
  min-height: 400px;
  background-color: #fff293;
background-image: linear-gradient(315deg, #fff293 0%, #f1e29e 74%);
  
  padding: 30px;
  color: rgb(8, 8, 8);
  margin-left: -150px;
  border-radius: 25px;
}

.right h1 {
  font-size: 40px;
  font-weight: lighter;
}
.right p {
  margin: 20px 0;
  font-weight: 500;
  line-height: 25px;
}

/*about*/


.about-main{
  background: #16222A;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #3A6073, #16222A);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #3A6073, #16222A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: #ffffff;
}

.about-sudhir{
  font-family: "Great Vibes", cursive;
  font-size: 80px;
  
}





@media only screen and (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    width: 100%;
    margin: 0 20px;
  }
  .left {
    width: 100%;
    height: 400px;
  }
  .right {
    width: 90%;
    margin: 0;
    margin-top: -100px;
  }
  .logo{
    height:40px;
    width:60px;
    margin-left:-20px;
  }
  
}

/*footer*/
.footer {
  background-color: #414141;
  width: 100%;
  text-align: left;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 16px;
  padding: 50px;
  
}

.footer .footer-left,
.footer .footer-center,
.footer .footer-right {
  display: inline-block;
  vertical-align: top;
}


/* footer left*/

.footer .footer-left {
  width: 33%;
  padding-right: 15px;
}

.footer .about {
  line-height: 20px;
  color: #ffffff;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
}

.footer .about span {
  display: block;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}






/* footer center*/

.footer .footer-center {
  width: 30%;
}

.footer .footer-center i {
  background-color: #33383b;
  color: #ffffff;
  font-size: 25px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
}

.footer .footer-center i.fa-envelope {
  font-size: 17px;
  line-height: 38px;
}

.footer .footer-center p {
  display: inline-block;
  color: #ffffff;
  vertical-align: middle;
  margin: 0;
}

.footer .footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.footer .footer-center p a {
  color: #0099ff;
  text-decoration: none;
}


/* footer right*/

.footer .footer-right {
  width: 35%;
}

.footer h2 {
  color: #ffffff;
  font-size: 36px;
  font-weight: normal;
  margin: 0;
}

.footer h2 span {
  color: #0099ff;
}

.footer .menu {
  color: #ffffff;
  margin: 20px 0 12px;
  padding: 0;
}

.footer .menu a {
  display: inline-block;
  line-height: 1.8;
  text-decoration: none;
  color: inherit;
}

.footer .menu a:hover {
  color: #0099ff;
}

.footer .name {
  color: #0099ff;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

@media (max-width: 767px) {
  .footer {
    font-size: 14px;
  }
  .footer .footer-left,
  .footer .footer-center,
  .footer .footer-right {
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }
  .footer .footer-center i {
    margin-left: 0;
  }

}



