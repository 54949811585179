.form-contain {
    margin: 50px auto;
    width:80%;
    height:100%;
    box-shadow: 0 5px 8px 0 rgb(197, 194, 194), 0 7px 20px 0 rgb(197, 194, 194);
    position:relative;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }



  .form {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .form h1 {
    font-size: 2rem;
    text-align: center;
    width: 80%;
    margin-bottom: 1rem;
    color: rgb(26, 153, 126);
  }
  
  .form-input-btn {
    width: 50%;
    height: 50px;
    margin-top: 10px;
    border-radius: 20px;
    background: linear-gradient(
      90deg,
      rgb(36, 146, 95) 0%,
      rgb(117, 224, 171) 100%
    );
    outline: none;
    border: none;
    color: white;
    font-size: 1rem;
  }

  .form-inputs{
    box-shadow: 0 5px 8px 0 rgb(197, 194, 194), 0 7px 20px 0 rgb(197, 194, 194);

  }
  
  .form-input-btn:hover {
    cursor: pointer;
    background: linear-gradient(
      90deg,
      rgb(18, 95, 72) 0%,
      rgb(79, 110, 91) 100%
    );
    
    transition: all 0.4s ease-out;
  }
  
  
  